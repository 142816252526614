* {
  margin: 0;
}

body {
  background-color:rgb(198, 231, 244);
  color: navy;
}

.textarea {
  background-color: beige;
  width: 100%;
  font-size: 20px;
  padding: 20px;
  border-radius: 15px;
  outline: none;
}

.textarea:focus {
  box-shadow: 0px 0px 5px 5px royalblue;
}

#preview {
  padding: 40px;
  text-align: left;
  background-color: beige; 
  color: black;
  border: 1px solid black;
  box-shadow: 1px 1px 5px 1px gray;
  border-radius: 15px;
}

#preview code {
  background-color: white;
  border-radius: 4px;
  padding: 5px 0px;
  font-size: 12px;
  font-weight: bold;
}

#preview pre {
  background-color: white;
  padding: 10px;
}


